<template>
  <div class="min-h-screen bg-custom-black-2">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div class="bg-custom-black-2 min-h-screen">
          <!-- Header -->
          <div class="p-0">
            <div
              class="flex justify-between items-center mb-6 bg-custom-black-2"
            >
              <div class="flex items-center w-full">
                <button @click="goBack" class="text-gray-400">
                  <ChevronLeftIcon class="h-6 w-6" />
                </button>
                <h2
                  class="text-sm font-semibold text-white flex-grow text-center"
                >
                  {{ t("myBets.title") }}
                </h2>
                <div class="w-6"></div>
              </div>
            </div>

            <!-- Date Range Picker -->
            <div class="flex flex-col items-center w-full bg-custom-bg-4">
              <div class="flex flex-col gap-4 w-full px-4 py-6">
                <div class="flex justify-center gap-2 w-full">
                  <input
                    type="date"
                    v-model="dateRange.start"
                    class="w-[120px] bg-white px-6 py-2 text-custom-black-2 border border-custom-black-2 rounded text-xs"
                  />
                  <input
                    type="date"
                    v-model="dateRange.end"
                    class="w-[120px] bg-white px-6 py-2 text-custom-black-2 border border-custom-black-2 rounded text-xs"
                  />
                  <button
                    @click="applyDateFilter"
                    class="bg-custom-bg-green text-white px-8 py-2 hover:bg-custom-green transition-colors rounded text-xs"
                  >
                    {{ t("myBets.filter") }}
                  </button>
                </div>

                <!-- Tabs -->
                <div class="flex justify-center gap-6 w-full">
                  <button
                    v-for="tab in tabs"
                    :key="tab.value"
                    @click="currentTab = tab.value"
                    :class="[
                      'px-4 py-1 text-white transition-colors text-xs',
                      currentTab === tab.value
                        ? 'border-b-2 border-red-500'
                        : 'hover:text-gray-300',
                    ]"
                  >
                    {{ t(`myBets.tabs.${tab.value}`) }}
                  </button>
                </div>

                <!-- Currency and User Selectors -->
                <div
                  class="flex justify-center gap-2 w-full"
                  v-if="loggedInUser.role !== 'user'"
                >
                  <!-- Currency Dropdown -->
                  <div class="relative w-[200px]">
                    <button
                      @click="toggleDropdown('currency')"
                      class="w-full px-4 py-2 text-white bg-custom-bg-4 flex justify-start items-center rounded text-xs"
                    >
                      {{ selectedCurrency || t("myBets.selectCurrency") }}
                      <ChevronDownIcon
                        :class="[
                          'h-5 w-5 transition-transform ml-2',
                          dropdowns.currency ? 'transform rotate-180' : '',
                        ]"
                      />
                    </button>

                    <div
                      v-if="dropdowns.currency"
                      class="absolute left-0 right-0 mt-1 bg-custom-bg-4 border border-gray-600 shadow-lg"
                    >
                      <div class="max-h-48 overflow-y-auto">
                        <button
                          v-for="currency in currencies"
                          :key="currency"
                          @click="selectCurrency(currency)"
                          class="w-full px-4 py-2 text-left text-white hover:bg-gray-700 flex justify-between items-center text-xs"
                        >
                          {{ currency }}
                          <CheckIcon
                            v-if="currency === selectedCurrency"
                            class="h-4 w-4 ml-2"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- User Dropdown -->
                  <div class="relative w-[200px]">
                    <button
                      @click="toggleDropdown('user')"
                      class="w-full px-4 py-2 text-white bg-custom-bg-4 flex justify-start items-center rounded text-xs"
                    >
                      {{
                        selectedUser
                          ? selectedUser.username
                          : t("myBets.selectUser")
                      }}
                      <ChevronDownIcon
                        :class="[
                          'h-5 w-5 transition-transform ml-2',
                          dropdowns.user ? 'transform rotate-180' : '',
                        ]"
                      />
                    </button>

                    <div
                      v-if="dropdowns.user"
                      class="absolute left-0 right-0 mt-1 bg-custom-bg-4 border border-gray-600 shadow-lg"
                    >
                      <input
                        v-model="userSearch"
                        type="text"
                        placeholder="Search users..."
                        class="w-full px-4 py-2 text-white bg-custom-bg-4 border-b border-gray-600 focus:outline-none text-xs"
                      />
                      <div class="max-h-48 overflow-y-auto">
                        <button
                          v-for="user in filteredUsers"
                          :key="user.id"
                          @click="selectUser(user)"
                          class="w-full px-4 py-2 text-left text-white hover:bg-gray-700 flex justify-between items-center text-xs"
                        >
                          {{ user.username }}
                          <CheckIcon
                            v-if="selectedUser && user.id === selectedUser.id"
                            class="h-4 w-4 ml-2"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Users List -->
            <div class="w-full">
              <div v-if="displayedUsers.length > 0">
                <div
                  v-for="user in displayedUsers"
                  :key="user.id"
                  class="border-b border-custom-white"
                >
                  <div
                    v-if="user.role !== 'user'"
                    @click="handleUserClick(user)"
                    :class="[
                      'flex justify-between items-center py-3 px-4 bg-custom-bg-green',
                      {
                        'cursor-pointer hover:bg-opacity-90':
                          !isLoadingUserDetails,
                      },
                      { 'cursor-not-allowed': isLoadingUserDetails },
                    ]"
                  >
                    <div class="flex items-center justify-between w-full">
                      <div class="flex items-center gap-4">
                        <span class="text-white">{{ user.username }}</span>
                        <span class="text-white font-light"
                          >({{ user.bet_stats.total_bets }})</span
                        >
                      </div>
                      <span class="text-white">
                        {{ formatNumber(user.bet_stats.total_stake) }}
                        
                      </span>
                    </div>
                  </div>

                  <!-- User Bets Grouped by Date -->
                  <div v-if="user.role === 'user'" class="bg-custom-bg-4">
                    <template
                      v-for="[date, bets] in groupedBets(user.bets)"
                      :key="date"
                    >
                      <!-- Date Header -->
                      <div class="bg-custom-black-3 py-2 px-4">
                        <span class="text-custom-white text-sm">{{
                          date
                        }}</span>
                      </div>

                      <!-- Bets for this date -->
                      <div
                        v-for="bet in bets"
                        :key="bet.id"
                        class="border-b border-custom-bg-3"
                      >
                        <!-- Bet Header -->
                        <div
                          class="flex items-center justify-between py-3 px-4 bg-custom-black cursor-pointer hover:bg-opacity-90"
                          @click="toggleBet(bet)"
                        >
                          <div class="flex items-center justify-between w-full">
                            <div
                              class="flex justify-between items-center space-x-1"
                            >
                              <span
                                class="text-white text-xs"
                                v-if="loggedInUser.role === 'user'"
                              >
                                {{ formatNumber(bet.stake) }}
                              </span>
                              <span class="text-white text-xs" v-else>
                                {{ user.username }}
                              </span>
                              <span
                                class="text-custom-white text-xs font-thin px-8"
                              >
                                {{ formatBetTime(bet.created_at) }} - N°{{
                                  bet.id
                                }}
                              </span>
                            </div>
                            <div class="flex items-center space-x-3">
                              <span
                                v-if="bet.status !== 'open'"
                                :class="[
                                  'text-xs',
                                  getBetStatusColor(bet.status),
                                ]"
                              >
                                {{ getBetStatusText(bet) }}
                              </span>
                              <button
                                v-if="
                                  isBetCancellable(bet) && bet.status === 'open'
                                "
                                @click.stop="cancelBet(bet.id)"
                                class="border border-outline-color text-outline-color px-4 py-1 rounded text-sm hover:bg-outline-color hover:text-white transition-colors"
                              >
                                {{ t("myBets.cancel") }}
                              </button>
                              <span
                                class="text-white text-xs"
                                v-else
                                v-html="getBetDisplayAmount(bet, user)"
                              >
                              </span>
                              <span v-if="bet.showSelections">
                                <i
                                  class="ri-share-line text-xl text-custom-white"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- Selections Accordion -->
                        <div
                          v-show="bet.showSelections"
                          class="bg-custom-bg-4 py-2"
                        >
                          <div
                            v-for="selection in bet.selections"
                            :key="selection.id"
                            class="px-2 py-2"
                          >
                            <div
                              class="flex items-center justify-between cursor-pointer"
                              @click="toggleSelection(selection)"
                            >
                              <div class="flex items-center gap-3">
                                <div
                                  :class="[
                                    'w-3 h-3 rounded-full mt-1',
                                    {
                                      'bg-custom-green': selection.status === 'won',
                                      'bg-red-500': selection.status === 'lost',
                                      'bg-white': selection.status === 'open',
                                      'bg-yellow-500': selection.status === 'void',
                                      'text-red-300':
                                        selection.status === 'cancelled',
                                    },
                                    'animate-bounce', // Tailwind animation
                                  ]"
                                ></div>

                                <div class="flex flex-col">
                                  <span class="text-white text-sm">
                                    {{ selection.match.home.name }} -
                                    {{ selection.match.away.name }}
                                  </span>
                                  <span class="text-gray-400 text-xs mt-1">
                                    {{ selection.name }}
                                  </span>
                                </div>
                              </div>
                              <div class="flex items-center space-x-3">
                                <span class="text-white text-sm">
                                  {{ selection.odds }}
                                </span>
                                <ChevronDownIcon
                                  :class="[
                                    'h-5 w-5 text-gray-400 transition-transform',
                                    selection.showEvents
                                      ? 'transform rotate-180'
                                      : '',
                                  ]"
                                />
                              </div>
                            </div>
                            <div v-if="selection.showEvents">
                              <MatchTimeline
                                v-if="selection.match && selection.match.stats && selection.match.stats[0] && selection.match.stats[0].events"
                                :events="selection.match.stats[0].events"
                                :homeTeam="selection.match.home.name"
                                :awayTeam="selection.match.away.name"
                              />
                              <div v-else class="text-white text-sm p-2">No match timeline available.</div>
                            </div>
                          </div>
                          <div class="flex justify-between mt-4 px-5 py-3 bg-custom-bg-3">
                            <div class="flex flex-col">
                              <span class="text-gray-400 text-xs">
                                {{ t("myBets.amount") }}
                              </span>
                              <span class="text-white text-sm">
                                {{ formatNumber(bet.stake) }}
                                {{ user.wallets && user.wallets[0] ? user.wallets[0].currency.code : '' }}
                              </span>
                            </div>
                            <div class="flex flex-col items-end">
                              <span class="text-gray-400 text-xs">
                                {{ t("myBets.toReturn") }}
                              </span>
                              <span class="text-white text-sm">
                                {{ formatNumber(bet.profit) }}
                                {{ user.wallets && user.wallets[0] ? user.wallets[0].currency.code : '' }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- Summary Row -->
                <div
                  v-if="currentUsers.some((u) => ['user'].includes(u.role))"
                  class="flex justify-between items-center py-3 px-4 bg-custom-white border-b border-custom-bg-3"
                >
                  <span class="text-custom-black text-sm">
                    {{ t("myBets.allUsers") }}
                  </span>
                  <span class="text-custom-black text-sm">
                    {{ formatNumber(totalStakesAllUsers) }}
                    {{ selectedCurrency }}
                  </span>
                </div>
              </div>
              <div v-else-if="!isLoadingUserDetails" class="text-center py-8">
                <p class="text-gray-400">{{ t("myBets.noBetsFound") }}</p>
              </div>
              <div
                v-if="isLoadingUserDetails"
                class="flex justify-center items-center py-4"
              >
                <SimpleSpinner />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ChevronLeftIcon, ChevronDownIcon, CheckIcon } from "lucide-vue-next";
import MainLayout from "../layouts/MainLayout.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import MatchTimeline from "../components/MatchTimeline.vue";
import axios from "axios";

const store = useStore();
const { t } = useI18n({
  messages: {
    en: {
      myBets: {
        title: "My Bets",
        filter: "Filter",
        today: "Today",
        yesterday: "Yesterday",
        tabs: {
          sports: "Sports",
          virtualSoccer: "Virtual Soccer",
          virtualGames: "Virtual Games",
        },
        betNumber: "Bet # {id}",
        betStatus: {
          won: "Won",
          lost: "Lost",
          open: "",
          void: "Void",
          cancelled: "Cancelled",
          half_won: "Half Won",
          half_lost: "Half Lost",
        },
        amount: "Amount",
        toReturn: "To Return",
        profit: "Profit",
        all: "All",
        allUsers: "All",
        cancel: "Cancel",
        noBetsFound: "No bets found",
        selectCurrency: "Currency",
        selectUser: "Select a user",
      },
    },
  },
});

const isLoading = computed(() => store.state.isLoading);
const sports = computed(() => store.state.sports || []);
const user = computed(() => store.getters["user"]);
const loggedInUser = computed(() => store.getters["user"]);
const currentTab = ref("sports");
const selectedCurrency = ref("");
const selectedUser = ref(null);
const currentUsers = ref([]);
const userStack = ref([]);
const isLoadingUserDetails = ref(false);
const dropdowns = ref({ currency: false, user: false });
const userSearch = ref("");

console.log('current users', currentUsers.value);

const formatBetDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatBetTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const groupBetsByDate = (bets) => {
  const grouped = bets.reduce((groups, bet) => {
    const date = formatBetDate(bet.created_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(bet);
    return groups;
  }, {});

  return Object.entries(grouped).sort((a, b) => {
    return (
      new Date(b[0].split("/").reverse().join("-")).getTime() -
      new Date(a[0].split("/").reverse().join("-")).getTime()
    );
  });
};

const groupedBets = (bets) => {
  return groupBetsByDate(bets);
};

const toggleDropdown = (type) => {
  dropdowns.value[type] = !dropdowns.value[type];
  if (dropdowns.value[type]) {
    Object.keys(dropdowns.value).forEach((key) => {
      if (key !== type) dropdowns.value[key] = false;
    });
  }
};

const selectCurrency = (currency) => {
  selectedCurrency.value = currency;
  dropdowns.value.currency = false;
  if (user.value.role === "user") {
    currentUsers.value = currentUsers.value.filter((u) =>
      u.bets.some((bet) => bet.wallet.currency.code === currency)
    );
  }
};

const selectUser = (user) => {
  selectedUser.value = user;
  handleUserClick(user);
  dropdowns.value.user = false;
};

const filteredUsers = computed(() => {
  return currentUsers.value.filter((user) =>
    user.username.toLowerCase().includes(userSearch.value.toLowerCase())
  );
});

const toggleSelection = (selection) => {
  if (selection) {
    if (selection.showEvents === undefined) {
      selection.showEvents = true;
    } else {
      selection.showEvents = !selection.showEvents;
    }
  }
};

const dateRange = ref({
  start: new Date().toISOString().split("T")[0],
  end: new Date().toISOString().split("T")[0],
});

const tabs = [
  { label: t("myBets.tabs.sports"), value: "sports" },
  { label: t("myBets.tabs.virtualSoccer"), value: "virtualSoccer" },
  { label: t("myBets.tabs.virtualGames"), value: "virtualGames" },
];

const currencies = ["EUR", "USD", "LEK"];

const isToday = ref(true);
const isYesterday = ref(false);
const isCurrentMonth = ref(false);

const setActiveDateFilter = (filter) => {
  isToday.value = filter === "today";
  isYesterday.value = filter === "yesterday";
  isCurrentMonth.value = filter === "currentMonth";
};

const getAuthToken = () => {
  const authData = JSON.parse(localStorage.getItem("forbet_auth") || "{}");
  return authData.accessToken;
};

const handleUserClick = (user) => {
  
    fetchUserDetails(user.id, dateRange.value.start, dateRange.value.end);
 
};

const toggleBet = (bet) => {
  bet.showSelections = !bet.showSelections;
};

const fetchUserDetails = async (userId, startDate, endDate) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  isLoadingUserDetails.value = true;
  try {
    const response = await axios.get(
      `https://api.newbet365.org/api/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          include_bet_stats: true,
        },
      }
    );

    currentUsers.value = response.data || [];
    console.log("DATE FILTER DATA: ", response.data);
    currentUsers.value.forEach(user => {
      if (user.bets) {
        user.bets.forEach(bet => {
          if (bet.selections) {
            bet.selections.forEach(selection => {
              selection.showEvents = false;
            });
          }
        });
      }
    });

  } catch (error) {
    console.error("Failed to fetch user details:", error);
  } finally {
    isLoadingUserDetails.value = false;
  }
};

const goBack = () => {
  if (userStack.value.length > 0) {
    currentUsers.value = userStack.value.pop();
  } else {
    window.history.back();
  }
};

const applyDateFilter = () => {
  
    fetchUserDetails(user.value.id, dateRange.value.start, dateRange.value.end);
    console.log("User details data fetched:", dateRange.value);
  
};

const filterToday = () => {
  const today = new Date().toISOString().split("T")[0];
  dateRange.value = { start: today, end: today };
  setActiveDateFilter("today");
  applyDateFilter();
};

const formatNumber = (value) => {
  if (!value) return "0.00";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(value));
};

const getBetStatusColor = (status) => {
  switch (status) {
    case "won":
    case "half_won":
      return "text-win-color";
    case "lost":
    case "half_lost":
      return "text-red-500";
    case "cancelled":
      return "text-red-300";
    default:
      return "text-yellow-500";
  }
};

const getBetStatusText = (bet) => {
  if (bet.status === "won" || bet.status === "half_won") {
    return `Won`;
  }
  return t(`myBets.betStatus.${bet.status}`);
};

const totalStakesAllUsers = computed(() => {
  return displayedUsers.value.reduce((total, user) => {
    return user.bets.reduce((userTotal, bet) => {
      let amount = parseFloat(bet.stake);
      if (bet.status === "won" || bet.status === "half_won") {
        amount -= parseFloat(bet.profit);
      }
      return userTotal + amount;
    }, total);
  }, 0);
});

const displayedUsers = computed(() => {
  if (user.value.role === "user") {
    return currentUsers.value.filter(
      (u) => u.id === user.value.id && u.bet_stats.total_bets > 0
    );
  }
  if (selectedUser.value) {
    return currentUsers.value.filter(
      (u) => u.id === selectedUser.value.id && u.bet_stats.total_bets > 0
    );
  }
  if (selectedCurrency.value) {
    return currentUsers.value.filter(
      (u) =>
        u.bet_stats.total_bets > 0 &&
        u.bets.some(
          (bet) => bet.wallet.currency.code === selectedCurrency.value
        )
    );
  }
  return currentUsers.value.filter((u) => u.bet_stats.total_bets > 0);
});

const isBetCancellable = (bet) => {
  if (user.value.role !== "user" && user.value.role !== "agent") return false;
  if (bet.status !== "open") return false;
  return bet.selections.every((selection) => selection.match.time_status === 0);
};

const cancelBet = async (betId) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  try {
    const response = await axios.post(
      `https://api.newbet365.org/api/bets/cancel`,
      { bet_id: betId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      await store.dispatch("updateBetStatus", { betId, status: "cancelled" });
      await store.dispatch("fetchUser");

      if (user.value.role === "user") {
        await fetchUserDetails(
          user.value.id,
          dateRange.value.start,
          dateRange.value.end
        );
      } else {
        await fetchUserDetails(
          user.value.id,
          dateRange.value.start,
          dateRange.value.end
        );
      }
    }
  } catch (error) {
    console.error(
      "Error cancelling bet:",
      error.response?.data?.error || error.message
    );
  }
};

const getBetDisplayAmount = (bet, user) => {
  const currency = user.wallets && user.wallets[0] ? user.wallets[0].currency.code : '';

  const isNotUserOrAgent = loggedInUser.value.role !== 'user' && loggedInUser.value.role !== 'agent';

  if (bet.status === "won" || bet.status === "half_won") {
    return `<span class="text-outline-color font-thin text-xs">${formatNumber(bet.profit)}</span> 
            <span class="text-outline-color font-thin text-xs">${currency}</span>`;
  } else if (bet.status === "open" || bet.status === "cancelled") {
    return `<span class="text-outline-color font-thin text-xs">${formatNumber(bet.stake)}</span> 
            <span class="text-outline-color font-thin text-xs">${currency}</span>`;
  } else if ((bet.status === "lost" || bet.status === "half_lost") && isNotUserOrAgent) {
    return `<span class="text-outline-color font-thin text-xs">${formatNumber(bet.stake)}</span> 
            <span class="text-outline-color font-thin text-xs">${currency}</span>`;
  }

  return "";
};


onMounted(async () => {
  try {
    await store.dispatch("fetchSports");
    await filterToday();
    console.log("Initial data fetch complete");
    await document.addEventListener("click", closeDropdowns);
  } catch (error) {
    console.error("Failed to fetch initial data:", error);
  }
});

const closeDropdowns = (e) => {
  if (!e.target.closest(".relative")) {
    Object.keys(dropdowns.value).forEach((key) => {
      dropdowns.value[key] = false;
    });
  }
};

onUnmounted(() => {
  document.removeEventListener("click", closeDropdowns);
});
</script>

<style scoped>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.relative {
  position: relative;
  z-index: 20;
}
</style>

