<template>
  <div
    v-if="isVisible"
    class="betslip fixed bottom-0 left-0 right-0 z-50 w-full bg-white shadow-lg"
  >
    <div class="container mx-auto">
      <!-- Header -->
      <div class="flex items-center justify-between p-2 bg-gray-100">
        <div class="flex items-center gap-2">
          <span
            class="bg-custom-bg-green text-white rounded-full w-6 h-6 flex items-center justify-center"
          >
            {{ selectionCount }}
          </span>
          <h2 class="text-gray-700">Bet Slip</h2>
        </div>
        <div class="text-lg font-semibold">{{ totalOdds.toFixed(2) }}</div>
        <button @click="hideBetslip" class="text-gray-500 hover:text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Bet Type Indicator -->
      <div
        class="bg-gray-200 p-2 text-center text-custom-green text-sm font-light"
      >
        {{ betType }} Bet
      </div>

      <div class="flex flex-col md:flex-row">
        <!-- Selections -->
        <div class="betslip-selections md:w-2/3 overflow-y-auto">
          <div
            v-for="selection in selections"
            :key="selection.matchId"
            class="p-3 border-b"
          >
            <div class="flex justify-between items-start">
              <div class="flex-1">
                <div class="text-sm">{{ selection.matchName }}</div>
                <div class="text-xs text-gray-500 flex items-center gap-1">
                  <span class="px-2 py-0.5 bg-blue-100 text-blue-700 rounded"
                    >Upcoming</span
                  >
                  <span>{{ selection.market }}</span>
                </div>
              </div>
              <button
                @click="removeSelection(selection.matchId, selection.marketId)"
                class="text-red-500 hover:text-red-700 text-xl font-bold"
              >
                ×
              </button>
            </div>
            <div class="text-right mt-2 font-semibold">
              {{ selection.odds }}
            </div>
          </div>
        </div>

        <div class="md:w-1/3">
          <!-- Stake Input and Place Bet -->
          <div class="flex flex-col">
            <div class="bg-gray-800 p-2">
              <input
                type="text"
                :value="stake"
                readonly
                @click="showKeypad = true"
                class="w-full bg-transparent text-white text-2xl focus:outline-none cursor-pointer"
                placeholder="0"
              />
            </div>
            <button
              @click="placeBet"
              :disabled="!canPlaceBet.allowed || isPlacingBet"
              class="bg-custom-bg-green text-white p-2 text-center relative"
              :class="{
                'opacity-50 cursor-not-allowed':
                  !canPlaceBet.allowed || isPlacingBet,
              }"
            >
              <div v-if="!isPlacingBet" class="text-center">
                <div class="text-sm">Place Bet</div>
                <div class="inline-flex items-end text-xl">
                  <span>{{ potentialWinnings }}</span>
                  <span class="text-xs align-bottom ml-1">{{
                    walletBalance.currency
                  }}</span>
                </div>
              </div>

              <div v-else class="flex items-center justify-center">
                <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Placing Bet...
              </div>
            </button>
          </div>

          <div
            v-if="!canPlaceBet.allowed"
            class="mt-2 p-2 bg-yellow-100 text-yellow-700 text-sm"
          >
            {{ canPlaceBet.reason }}
          </div>

          <!-- Error Message -->
          <div
            v-if="betError"
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2"
            role="alert"
          >
            <span class="block sm:inline">{{ betError }}</span>
          </div>

          <!-- Wallet Balance -->
          <div class="mt-2 p-2 bg-gray-100">
            <div class="text-sm text-gray-600">
              Wallet Balance: {{ walletBalance.balance }}
              {{ walletBalance.currency }}
            </div>
          </div>

          <!-- Keypad -->
          <div v-if="showKeypad" class="grid grid-cols-3 border-t">
            <button
              v-for="n in 9"
              :key="n"
              @click="appendToStake(n.toString())"
              class="p-4 text-center border-r border-b hover:bg-gray-100"
            >
              {{ n }}
            </button>
            <button
              @click="appendToStake('.')"
              class="p-4 text-center border-r border-b hover:bg-gray-100"
            >
              .
            </button>
            <button
              @click="appendToStake('0')"
              class="p-4 text-center border-r border-b hover:bg-gray-100"
            >
              0
            </button>
            <button
              @click="backspace"
              class="p-4 text-center border-r border-b hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const showKeypad = ref(false);
const isPlacingBet = ref(false);

const selections = computed(() => store.state.betslip.selections);
const stake = computed(() => store.state.betslip.stake);
const isVisible = computed(() => store.state.betslip.isVisible);
const totalOdds = computed(() => store.getters["betslip/totalOdds"]);
const potentialWinnings = computed(
  () => store.getters["betslip/potentialWinnings"]
);
const selectionCount = computed(() => store.getters["betslip/selectionCount"]);
const canPlaceBet = computed(() => store.getters["betslip/canPlaceBet"]);
const betError = computed(() => store.state.betslip.error);

const betType = computed(() =>
  selections.value.length > 1 ? "Multiples" : "Single"
);

const walletBalance = computed(() => {
  const user = store.getters["user"];
  if (user?.wallets && user.wallets.length > 0) {
    const wallet = user.wallets[0];
    return {
      balance: wallet.balance,
      symbol: wallet.currency?.symbol || "$",
      currency: wallet.currency.code,
    };
  }
  return { balance: "0", symbol: "$" };
});

const appendToStake = (value) => {
  let newStake = stake.value.toString();

  if (value === "." && newStake.includes(".")) {
    return;
  }

  newStake = newStake + value;

  store.dispatch("betslip/updateStake", parseFloat(newStake));
};

const backspace = () => {
  let newStake = stake.value.toString();
  if (newStake.length > 0) {
    newStake = newStake.slice(0, -1);
    store.dispatch(
      "betslip/updateStake",
      newStake === "" ? 0 : parseFloat(newStake)
    );
  }
};

const hideBetslip = () => {
  store.dispatch("betslip/toggleVisibility", false);
  showKeypad.value = false;
};

const removeSelection = (matchId, marketId) => {
  store.dispatch("betslip/addOrRemoveSelection", { matchId, marketId });
};

const placeBet = async () => {
  if (!store.getters["isAuthenticated"]) {
    store.commit("betslip/SET_ERROR", "Please log in to place a bet");
    return;
  }

  isPlacingBet.value = true;
  try {
    const success = await store.dispatch("betslip/placeBet");
    if (success) {
      hideBetslip();
      // You might want to show a success message here
      await store.dispatch("fetchUser");
    } else {
      // The error message is already set in the store
      console.error("Failed to place bet:", store.state.betslip.error);
    }
  } finally {
    isPlacingBet.value = false;
  }
};
</script>

<style scoped>
.betslip-selections {
  max-height: 200px;
}

@media (min-width: 768px) {
  .betslip-selections {
    max-height: 400px;
  }
}
</style>

