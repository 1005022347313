<template>
  <div class="min-h-screen bg-custom-black">
    <MainLayout :sports="sports">
      <div class="bg-custom-black py-1 px-1">
        <div class="w-full max-w-4xl mx-auto">
          <!-- Header with All dropdown -->
          <div class="flex justify-between items-center mb-4">
            <div class="flex items-center">
              <button class="text-gray-400 mr-2">
                <ChevronLeftIcon class="h-6 w-6" />
              </button>
              <h2 class="text-xl font-semibold text-white">{{ t('accountBalance.title') }}</h2>
            </div>
            <div class="relative">
              <button
                @click="toggleDropdown"
                class="flex items-center text-white space-x-1"
              >
                <span>{{ t('accountBalance.all') }}</span>
                <ChevronDownIcon class="h-5 w-5" />
              </button>
              <!-- Dropdown menu (add your options here) -->
            </div>
          </div>

          <!-- Date Range Picker -->
          <div class="flex flex-col items-center w-full bg-custom-bg-4">
            <div class="flex flex-col gap-4 w-full px-4 py-6">
              <div class="flex justify-center gap-2 w-full">
                <input
                  type="date"
                  v-model="dateRange.start"
                  class="w-[120px] bg-white px-6 py-2 text-custom-black-2 border border-custom-black-2 rounded text-xs"
                />
                <input
                  type="date"
                  v-model="dateRange.end"
                  class="w-[120px] bg-white px-6 py-2 text-custom-black-2 border border-custom-black-2 rounded text-xs"
                />
                <button
                  @click="applyDateFilter"
                  class="bg-custom-bg-green text-white px-8 py-2 hover:bg-custom-green transition-colors rounded text-xs"
                >
                  {{ t("myBets.filter") }}
                </button>
              </div>
            </div>
          </div>

          <!-- Quick Filters -->
          <div
            class="flex flex-wrap w-full justify-between mb-6 border-b border-custom-bg-2"
          >
            <button
              v-for="filter in quickFilters"
              :key="filter.value"
              @click="setActiveDateFilter(filter.value)"
              :class="[
                'pb-2 px-1 text-gray-300 relative',
                (filter.value === 'today' && isToday) ||
                (filter.value === 'yesterday' && isYesterday) ||
                (filter.value === 'currentMonth' && isCurrentMonth) ||
                (filter.value === 'currentYear' && isCurrentYear)
                  ? 'text-white border-b-2 border-red-500 -mb-[1px]'
                  : 'hover:text-white',
              ]"
            >
              {{ filter.label }}
            </button>
          </div>

          <!-- Bet Stats Table -->
          <div v-if="isLoading" class="text-white text-center py-4">
            Loading...
          </div>
          <div v-else-if="transformedStats.daily.length === 0" class="text-white text-center py-4">
            No data available for the selected date range.
          </div>
          <div v-else class="bg-[#1C1C1C] overflow-x-auto">
            <table class="w-full text-left border-collapse text-xs border-t border-white/40">
              <thead>
                <tr class="bg-custom-white text-custom-bg-2 border-b border-white/40">
                  <th class="p-2 font-light">{{ t('accountBalance.table.info') }}</th>
                  <th class="p-2 font-light">{{ t('accountBalance.table.totalBets') }}</th>
                  <th class="p-2 font-light">{{ t('accountBalance.table.winnings') }}</th>
                  <th v-if="showCommissionColumn" class="p-2 font-light">
                    {{ t('accountBalance.table.commission') }}
                    <!-- <span v-if="currentUser?.role === 'manager'" 
                          class="text-xs text-gray-400">(Profit from Senior)</span> -->
                  </th>
                  <th class="p-2 font-light">{{ t('accountBalance.table.netReturn') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="day in transformedStats.daily" :key="day.date" 
                    class="text-gray-300 border-b border-custom-white">
                  <td class="p-2">{{ formatDate(day.date) }}</td>
                  <td class="p-2">{{ formatNumber(day.total_stake) }}</td>
                  <td class="p-2">{{ formatNumber(day.winnings) }}</td>
                  <td v-if="showCommissionColumn" class="p-2">{{ formatNumber(day.commission) }}</td>
                  <td class="p-2">{{ formatNumber(day.net_return) }}</td>
                </tr>
                <!-- Summary row -->
                <tr class="text-gray-300 border-b border-custom-white font-medium">
                  <td class="p-2">{{ t('accountBalance.table.all') }}</td>
                  <td class="p-2">{{ formatNumber(transformedStats.totals.total_stake) }}</td>
                  <td class="p-2">{{ formatNumber(transformedStats.totals.winnings) }}</td>
                  <td v-if="showCommissionColumn" class="p-2">{{ formatNumber(transformedStats.totals.commission) }}</td>
                  <td class="p-2">{{ formatNumber(transformedStats.totals.net_return) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { ChevronLeftIcon, ChevronDownIcon } from "lucide-vue-next";
import MainLayout from "../layouts/MainLayout.vue";
import axios from 'axios';

const store = useStore();
const { t } = useI18n();
const sports = computed(() => store.state.sports || []);
const currentUser = computed(() => store.getters["user"]);

const showDropdown = ref(false);

const isToday = ref(true);
const isYesterday = ref(false);
const isCurrentMonth = ref(false);
const isCurrentYear = ref(false);

const isLoading = ref(true);

const currentDate = new Date();
const currentMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);
const currentYear = currentDate.getFullYear();

const dateRange = ref({
  start: new Date().toISOString().split("T")[0],
  end: new Date().toISOString().split("T")[0],
});

const userStats = ref(null);

const getAuthToken = () => {
  const authData = JSON.parse(localStorage.getItem("forbet_auth") || "{}");
  return authData.accessToken;
};

const fetchUserStats = async (startDate, endDate) => {
  isLoading.value = true;
  try {
    const token = getAuthToken();
    const response = await axios.get(
      `https://api.newbet365.org/api/user/betstats`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          include_role: true
        },
      }
    );
    userStats.value = response.data;
    console.log("Fetched user stats:", userStats.value);
  } catch (error) {
    console.error("Failed to fetch user stats:", error);
  } finally {
    isLoading.value = false;
  }
};

const showCommissionColumn = computed(() => {
  console.log("Current user:", currentUser.value);
  console.log("User role:", currentUser.value?.role);
  return ['senior', 'manager'].includes(currentUser.value?.role) ?? false;
});

const transformedStats = computed(() => {
  if (!userStats.value) return { daily: [], totals: { total_stake: 0, winnings: 0, net_return: 0, commission: 0 } };

  // Convert daily_stats object into array and filter out days with no activity
  const daily = Object.entries(userStats.value.daily_stats)
    .map(([date, stats]) => ({
      date,
      total_stake: Number(stats.total_stake),
      winnings: Number(stats.winnings),
      net_return: Number(stats.net_return),
      commission: Number(stats.commission)
    }))
    .filter(day => day.total_stake > 0 || day.winnings > 0 || day.net_return !== 0 || day.commission !== 0)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  // Calculate totals
  const totals = daily.reduce((acc, curr) => ({
    total_stake: acc.total_stake + curr.total_stake,
    winnings: acc.winnings + curr.winnings,
    net_return: acc.net_return + curr.net_return,
    commission: acc.commission + curr.commission
  }), {
    total_stake: 0,
    winnings: 0,
    net_return: 0,
    commission: 0
  });

  return { daily, totals };
});

const setActiveDateFilter = (filter) => {
  isToday.value = filter === "today";
  isYesterday.value = filter === "yesterday";
  isCurrentMonth.value = filter === "currentMonth";
  isCurrentYear.value = filter === "currentYear";

  // Set date range based on the selected filter
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  switch (filter) {
    case "today":
      dateRange.value.start = today.toISOString().split("T")[0];
      dateRange.value.end = today.toISOString().split("T")[0];
      break;
    case "yesterday":
      dateRange.value.start = yesterday.toISOString().split("T")[0];
      dateRange.value.end = yesterday.toISOString().split("T")[0];
      break;
    case "currentMonth":
      dateRange.value.start = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split("T")[0];
      dateRange.value.end = today.toISOString().split("T")[0];
      break;
    case "currentYear":
      dateRange.value.start = new Date(today.getFullYear(), 0, 1).toISOString().split("T")[0];
      dateRange.value.end = today.toISOString().split("T")[0];
      break;
  }

  // Fetch user stats for the selected date range
  fetchUserStats(dateRange.value.start, dateRange.value.end);
};

const quickFilters = [
  { label: t('accountBalance.quickFilters.today'), value: "today" },
  { label: t('accountBalance.quickFilters.yesterday'), value: "yesterday" },
  { label: currentMonthName, value: "currentMonth" },
  { label: currentYear.toString(), value: "currentYear" },
];

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const applyDateFilter = () => {
  fetchUserStats(dateRange.value.start, dateRange.value.end);
};

const formatNumber = (number) => {
  if (number == null) return '0';
  return Number(number).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatDate = (dateStr) => {
  return new Date(dateStr).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

onMounted(async () => {
  console.log("THIS COMPONENT HAS MOUNTED");
  if (currentUser.value) {
    console.log("User from account balance component: ", currentUser.value);
    console.log("User role: ", currentUser.value.role);
  } else {
    console.log("Current user is not available");
  }
  try {
    await store.dispatch("fetchSports");
    // Fetch today's stats when the component mounts
    await fetchUserStats(dateRange.value.start, dateRange.value.end);
  } catch (error) {
    console.error("Failed to fetch initial data:", error);
  }
});

// Add a watcher for currentUser
watch(currentUser, (newUser) => {
  console.log("Current user updated:", newUser);
  if (newUser) {
    fetchUserStats(dateRange.value.start, dateRange.value.end);
  }
});
</script>

<style scoped>
/* Hide default date input styling */
input[type="text"] {
  position: relative;
}

table {
  border-spacing: 0;
  border-collapse: separate;
}

tbody tr {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

tbody tr:first-child {
  border-top: none;
}

th,
td {
  border-bottom: none;
  padding-top: 8px;
  padding-bottom: 8px;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .flex-wrap > * {
    margin-bottom: 0.5rem;
  }
}
</style>

